/*-----------------------------------------------------------*/
/* Account Edit Address Box - by @RaulPop                                             */
/*-----------------------------------------------------------*/
import $ from 'jquery';

$('[cb-acc-element=open-edit-address]').click(function () {
  $('.lay__box').addClass('inactive');
  $(this).parents('[cb-acc-element=box]').addClass('active-edit');
  $(this).parents('[cb-acc-element=box]').find('input').eq(0)
    .focus();
});

$('[cb-acc-element=close-edit]').click(() => {
  $('.lay__box').removeClass('inactive active-edit');
  $('.lay__box').find('input').blur();
});

/*-----------------------------------------------------------*/
/* Search Box - header dropdown                              */
/*-----------------------------------------------------------*/

$(".search__input").on("focus", function () {
  if (($('#search').val() === "") && $(".no-search-entries").hasClass("hidden") && ($('.search__last').length == 0) && ($('.search__products').length == 0)) {
    $(".no-search-entries").removeClass("hidden");
  } else {
    if (!$(".no-search-entries").hasClass("hidden")) {
      $(".no-search-entries").addClass("hidden");
    }
  }
});

var typingTimerSearch;
$(".search__input").on("input", function() {
  var input_val = $(this).val();

  clearTimeout(typingTimerSearch);
  typingTimerSearch = setTimeout(function () {
    if (input_val === "") {
      if ($(".no-search-entries").hasClass("hidden") && ($('.search__last').length == 0) && ($('.search__products').length == 0)) {
        $(".no-search-entries").removeClass("hidden");
      }
      search_history();
    } else {
      if (!$(".no-search-entries").hasClass("hidden")) {
        $(".no-search-entries").addClass("hidden");
      }
      search_autocomplete(input_val);
    }
  }, 300);

});


$('#search').click(function() {
  if ($(this).val() === "") {
    if ($(".no-search-entries").hasClass("hidden") && ($('.search__last').length == 0) && ($('.search__products').length == 0)) {
      $(".no-search-entries").removeClass("hidden");
    }
    search_history();
  }
});

$(document).ready(function () {
  $('.show-more').on('click', function (e) {
    e.preventDefault();
    $(this).closest('.utl-acc__list-wrapper').find('.prd-pg__acc__text-wrapper').css('max-height', 'none');
    $(this).hide();
  });
});

function search_history() {
    var url_search_history = $("#url__search_history").val();

    const form = $('form.search');
    var url_search = form.attr("action");

    // Clear the previous results
    $('.search__last').empty();

    $.ajax({
      url: url_search_history,
      method: 'GET',
      success: function(response) {
        if (!response.error && (response.count > 0)) {
          if (!$(".no-preview-last-entries").hasClass("hidden")) {
            $(".no-preview-last-entries").addClass("hidden");
          }
          response.data.forEach(function(search) {
            var content = '<a href="' + url_search + '?q=' + search + '" class="search__last__item w-inline-block">' +
          '<div class="search__results__icon w-embed">' +
          '<svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">' +
          '<g id="Gruppe_2877" data-name="Gruppe 2877" transform="translate(7021 20744.291)">' +
          '<g fill="#222" id="Gruppe_2875" data-name="Gruppe 2875">' +
          '<path id="Pfad_3058" data-name="Pfad 3058" d="M11.37,20.436a6.162,6.162,0,0,0-8.644-1.1V18.044H1.332V20.8a1.125,1.125,0,0,0,1.123,1.121H5.21V20.529H3.465a4.768,4.768,0,1,1-1.733,3.678v-.2H.339v.2A6.161,6.161,0,1,0,11.37,20.436" transform="translate(-7021 -20761.998)"></path>' +
          '<path id="Pfad_3059" data-name="Pfad 3059" d="M5.8,21.523V24.5l1.837,1.84.985-.985L7.2,23.923v-2.4Z" transform="translate(-7021 -20761.998)"></path>' +
          '</g></g></svg></div><div>' + search + '</div></a>';

            $('.search__last').append(content);
          });
        } else {
          if ($(".no-preview-last-entries").hasClass("hidden")) {
            $(".no-preview-last-entries").removeClass("hidden");
          }
        }
      },
      error: function(err) {
        console.error(err);
      }
    });

    var url_rvp = $("#url__search_rvp").val();

    // Clear the previous results
    $('.search__products').empty();

    $.ajax({
      url: url_rvp,
      method: 'GET',
      success: function(response) {
        if (!response.error && (response.count > 0)) {

          if (!$(".no-preview-entries").hasClass("hidden")) {
            $(".no-preview-entries").addClass("hidden");
          }

          response.data.forEach(function(search) {
            let path_img = '';
            if (search.image){
              path_img = search.image
            } else{
              path_img = path_placeholder_img
            }
            var content = '<a href="' + search.url + '" class="search__product w-inline-block">' +
            '<div class="search__product__image-wrapper">' +
            '<img src="' + path_img + '" loading="lazy" alt="" class="search__product__image" />' +
            '</div>' +
            '<div class="search__product__info">' +
            '<div class="search__product__title">' + search.name + '</div>' +
            '<div class="search__product__price"> ' + search.price + '</div>' +
            '</div></a>';
            $('.search__products').append(content);
          });
        } else {
          if ($(".no-preview-entries").hasClass("hidden")) {
            $(".no-preview-entries").removeClass("hidden");
          }
        }
      },
      error: function(err) {
        console.error(err);
      }
    });
  }

  function search_autocomplete(search_term) {
    var url__search_autocomplete = $("#url__search_autocomplete").val();

    // Clear the previous results
    $('.search__last_autocomplete').empty();

    $.ajax({
      url: url__search_autocomplete + '?q=' + search_term,
      method: 'GET',
      success: function(response) {
        if (response.count > 0 ) {

          if (!$(".no-autocomplete-entries").hasClass("hidden")) {
            $(".no-autocomplete-entries").addClass("hidden");
          }

          response.data.forEach(function(search) {
          var content = '<a href="' + search.url + '" class="search__last__item w-inline-block">' +
              '<div class="search__results__icon w-embed">' +
              '<svg xmlns="http://www.w3.org/2000/svg" width="13" height="13.002" viewBox="0 0 13 13.002">' +
              '<g id="Gruppe_2876" data-name="Gruppe 2876" transform="translate(7021 20762)">' +
              '<path id="Pfad_3057" data-name="Pfad 3057" d="M10.089,9.028a5.536,5.536,0,1,0-4.974,2.165,5.571,5.571,0,0,0,.566.029,5.408,5.408,0,0,0,.586-.032,5.509,5.509,0,0,0,2.761-1.1L11.8,12.86l1.06-1.06Zm-4.408.694A4.041,4.041,0,1,1,9.722,5.681,4.046,4.046,0,0,1,5.681,9.722" transform="translate(-7021 -20761.998)" fill="currentColor"></path>' +
              '</g></svg></div><div>' + search.name + '</div></a>';

              $('.search__last_autocomplete').append(content);
          });
        } else {
          if ($(".no-autocomplete-entries").hasClass("hidden")) {
            $(".no-autocomplete-entries").removeClass("hidden");
          }
        }
      },
      error: function(err) {
        console.error(err);
      }
    });
  }
/*-----------------------------------------------------------*/
/* END - Search Box - header dropdown                        */
/*-----------------------------------------------------------*/


/*-----------------------------------------------------------*/
/* START - DELETE ADDRESS MODAL                        */
/*-----------------------------------------------------------*/
$(document).ready(() => {
  $('[data-requires-confirmation-custom]').requireConfirmationCustom();

  $(document).ready(function() {
    var modal = $("#modal-delete");
    var closeButton = modal.find("[cb-modal-element='close']");
    var noButton = modal.find(".utl__button.neg-outline");

    closeButton.click(function() {
      modal.removeClass("modal-active").addClass("modal-inactive");
    });
    noButton.click(function() {
      modal.removeClass("modal-active").addClass("modal-inactive");
    });
  });
});

$.fn.extend({
  requireConfirmationCustom() {
    this.each((idx, el) => {
      $(el).on('click', (evt) => {
        evt.preventDefault();

        const actionButton = $(evt.currentTarget);

        if (actionButton.is('a')) {
          $('#confirmation-button').on('click', (event) => {
            event.preventDefault();

            actionButton.closest('form').submit();
          });
        }

        $(`#modal-delete`).removeClass("modal-inactive").addClass("modal-active");
      });
    });
  },
});
/*-----------------------------------------------------------*/
/* END - DELETE ADDRESS MODAL                        */
/*-----------------------------------------------------------*/

/*-----------------------------------------------------------*/
/* START - MORE BLOGS BUTTON                                 */
/*-----------------------------------------------------------*/

$(document).ready(function() {
  var blogsPerPage = 6;
  var totalDisplayedBlogs = 0;
  showBlogs();

  $('a[cb-load-element="more-button-blog"]').on('click', function(e) {
      e.preventDefault();
      showBlogs();
  });

  function showBlogs() {
      var totalBlogs = $('.blog-item').length;
      var remainingBlogs = totalBlogs - totalDisplayedBlogs;
      var blogsShow = Math.min(remainingBlogs, blogsPerPage);

      $('.blog-item').hide().slice(0, totalDisplayedBlogs + blogsShow).show();

      totalDisplayedBlogs += blogsShow;

      var progress = (totalBlogs > 0) ? (totalDisplayedBlogs / totalBlogs) * 100 : 0;
      // $('.utl-load__progress-bar').css('width', Math.min(progress, 100) + '%');
      //
      // $('.utl-load__current_blog').text(totalDisplayedBlogs + ' von ' + totalBlogs);

      if (totalDisplayedBlogs >= totalBlogs) {
          $('a[cb-load-element="more-button-blog"]').hide();
      }
  }
});


/*-----------------------------------------------------------*/
/* END - MORE BLOGS BUTTON                                 */
/*-----------------------------------------------------------*/

/*-----------------------------------------------------------*/
/* START - CAROUSEL FIX                                      */
/*-----------------------------------------------------------*/

var width_container = $(".lay__container").find(".lay__container").find(".utl-header__wrapper").width();
if (width_container) {
  $(".lay__carousel__wrapper").width(width_container);
}

if ($(".lay__carousel__wrapper").parents('.lay__column').length > 0) {
  var width = $(".lay__column").width();
  $(".lay__column").find(".lay__carousel__wrapper").width(width);
}

/*-----------------------------------------------------------*/
/* END - CAROUSEL FIX                                        */
/*-----------------------------------------------------------*/

/*-----------------------------------------------------------*/
/* START - MORE BLOGS BUTTON                                 */
/*-----------------------------------------------------------*/

$(document).ready(function() {
  var blogsPerPage = 6;
  var totalDisplayedBlogs = 0;
  showBlogs();

  $('a[cb-load-element="more-button-blog"]').on('click', function(e) {
      e.preventDefault();
      showBlogs();
  });

  function showBlogs() {
      var totalBlogs = $('.blog-item').length;
      var remainingBlogs = totalBlogs - totalDisplayedBlogs;
      var blogsShow = Math.min(remainingBlogs, blogsPerPage);

      $('.blog-item').hide().slice(0, totalDisplayedBlogs + blogsShow).show();

      totalDisplayedBlogs += blogsShow;

      var progress = (totalBlogs > 0) ? (totalDisplayedBlogs / totalBlogs) * 100 : 0;
      $('#utl-load__progress-bar').css('width', Math.min(progress, 100) + '%');

      $('.utl-load__current_blog').text(totalDisplayedBlogs + ' von ' + totalBlogs);

      if (totalDisplayedBlogs >= totalBlogs) {
          $('a[cb-load-element="more-button-blog"]').hide();
      }
  }
});


/*-----------------------------------------------------------*/
/* END - MORE BLOGS BUTTON                                 */
/*-----------------------------------------------------------*/

/*-----------------------------------------------------------*/
/* START - CAROUSEL FIX                                      */
/*-----------------------------------------------------------*/

var width_container = $(".lay__container").find(".lay__container").find(".utl-header__wrapper").width();
if (width_container) {
  $(".lay__carousel__wrapper").width(width_container);
}

if ($(".lay__carousel__wrapper").parents('.lay__column').length > 0) {
  var width = $(".lay__column").width();
  $(".lay__column").find(".lay__carousel__wrapper").width(width);
}

/*-----------------------------------------------------------*/
/* END - CAROUSEL FIX                                        */
/*-----------------------------------------------------------*/


/*----------------------------------------------------------------------------------------*/
/* START - MANAGE ADDRESSES (on checkout)                                                 */
/* @TODO: adapt and apply to account section                                              */
/*----------------------------------------------------------------------------------------*/

$(document).ready(function () {

  // Handle OnePageCheckout forms ==========================================================
  $('body').on('submit', '.opc_form', function(event){
    event.preventDefault();
    showSpinner();
    var form = $(this);
    let method = form.attr('method');
    let url = form.attr('action');
    let data = form.serializeArray()
    let headers = { 'X-Requested-With': 'XMLHttpRequest' }
    let wrapper = form.closest('.partial-form-wrapper');

    $.ajax({
      type: method,
      url: url,
      data: data,
      headers: headers,
      success: function (response) {
        if(response.redirect) {
          window.location.href = response.redirect;
        } else {
          $.ajax({
            type: "GET",
            url: url,
            headers: headers,
            success: function (html) {
              wrapper.html(html);

              // Update the summary section

              let summaryWrapper = $('#_partial_summary');
              let summaryForm = $('form#sylius_checkout_complete');
              let summaryFormUrl = summaryForm.attr('action');

              $.ajax({
                type: "GET",
                url: summaryFormUrl,
                headers: headers,
                success: function (html) {
                  summaryWrapper.html(html);
                  hideSpinner();
                },
                error: function (xhr, status, error) {
                  // @TODO: handle errors
                  console.error('Form submission error:', error);
                  hideSpinner();
                }
              });
            },
            error: function (xhr, status, error) {
              // @TODO: handle errors
              console.error('Form submission error:', error);
              hideSpinner();
            }
          });
        }
      },
      error: function (xhr, status, error) {
        // @TODO: handle errors
        console.error('Form submission error:', error);
        hideSpinner();
      }
    });
  });

  // Handle delete-address triggers ========================================================
  $('body').on('click', '.delete-address-trigger', function(event){
    event.preventDefault();

    var actionUrl = $(this).data('action-url');

    renderConfirmAddressDeleteModal(event.currentTarget, actionUrl)
  });

  // Handle manage-address triggers ========================================================
  $('body').on('click', '.manage-address-trigger', function(event){
    event.preventDefault();

    var actionUrl = $(this).data('action-url');

    $.ajax({
      url: actionUrl,
      type: 'GET',
      dataType: 'html',
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      },
      success: function (html) {
        $("#shipping-address").hide();
        $('#rendered-manage-address').html(html);

        var addressForm = $('#rendered-manage-address .manage-address-form');
        var submitButton = $('#rendered-manage-address .submit-manage-address-form');
        var closeButton =  $('#rendered-manage-address [cb-acc-element="close"]');

        submitButton.on('click', function (event) {
          event.preventDefault();
          showSpinner();

          $.ajax({
            url: addressForm.attr('action'),
            type: addressForm.attr('method'),
            data: addressForm.serialize(),
            success: function (response) {
              location.reload(true);
            },
            error: function (jqXHR, textStatus, errorThrown) {
              if (jqXHR.responseJSON && jqXHR.responseJSON.errors) {
                handleFormErrors(jqXHR.responseJSON.errors);
              } else {
                console.error('Error:', textStatus, errorThrown);
              }
              hideSpinner();
            }
          });
        });

        closeButton.on('click', function (event) {
          $("#shipping-address").css("display", "flex");

          $(".lay__box").removeClass("active inactive");
          $(".lay__box").find("input").blur();

          // to show the edit element inside this box, because it holds the list of addresses
          $(this).closest(".lay__box").addClass("active inactive");

          // clear the div used for rendering the address form
          $('#rendered-manage-address').empty();
        });

      },
      error: function (jqXHR, textStatus, errorThrown) {
        if (jqXHR.status === 423) {
          renderAddressBlockedModal();
        }
        console.error('Error fetching form:', textStatus, errorThrown);
      }
    });
  });
  // =======================================================================================

  // Handle edit-billing-address triggers ==================================================
  $('body').on('click', '.edit-billing-address-trigger', function(event){
    event.preventDefault();
    renderAddressBlockedModal();
  });
  //========================================================================================

  function handleFormErrors(errors) {
    for (var key in errors) {
      if (errors.hasOwnProperty(key)) {
        var errorMsg = errors[key];
        var formField = $('#' + key);
        var errorElement = formField.closest('.utl-ipt__block').find('.utl-ipt__info__text');
        errorElement.html(errorMsg);
      }
    }
    setStyleErrors();
  }

  function setStyleErrors() {
    var inputBlocks = $('.customer_address_block .utl-ipt__block');
    inputBlocks.each(function () {
      var errorTextElement = $(this).find('.utl-ipt__info__text');
      if (errorTextElement.text().trim() !== '') {
        $(this).attr('cb-input-status', 'false');
      }
    });
  }

  function renderConfirmAddressDeleteModal(trigger, actionUrl) {

    // Clone the confirmation modal skeleton, populate it and append it to the document body
    var modalConfirmDeleteAddressElement = $($('#modalConfirmDeleteAddressTemplate').html()).appendTo('body');
    modalConfirmDeleteAddressElement.removeClass('modal-inactive');

    // Close confirmation modal logic on clicking the close button
    modalConfirmDeleteAddressElement.on('click', '[cb-modal-element="close"]', function () {
      modalConfirmDeleteAddressElement.remove();
    });

    // Handle confirmation button click
    modalConfirmDeleteAddressElement.on('click', '#modalConfirmButton', function () {
      showSpinner();

      $.ajax(actionUrl, {
        method: 'DELETE',
        contentType: 'application/json',
        success: function (data, textStatus, jqXHR) {
          if (jqXHR.status === 200 || data.success) {
            var label = $(trigger).closest('label');
            if (label.length) label.remove();
          }
          modalConfirmDeleteAddressElement.remove();
          hideSpinner();
        },
        error: function (jqXHR, textStatus, errorThrown) {
          modalConfirmDeleteAddressElement.remove();
          hideSpinner();
          if (jqXHR.status === 423) {
            renderAddressBlockedModal();
          } else {
            var errorMessage = jqXHR.responseJSON && jqXHR.responseJSON.error ? jqXHR.responseJSON.error : null;
            renderGenericErrorModal(errorMessage);
          }
          console.error('Error:', textStatus, errorThrown);
        }
      });
    });

  }

  function renderAddressBlockedModal() {
    // Clone the warning modal skeleton, populate it and append it to the document body
    var modalWarningElement = $($('#modalBlockeBillingdAdress').html()).appendTo('body');
    modalWarningElement.removeClass('modal-inactive');

    // Close confirmation modal logic on clicking the close button
    modalWarningElement.on('click', '[cb-modal-element="close"]', function () {
      modalWarningElement.remove();
    });
  }

  function renderGenericErrorModal(errorMessage) {
    // Clone the warning modal skeleton, populate it and append it to the document body
    var modalGenericErrorElement = $($('#modalGenericErrorTemplate').html()).appendTo('body');

    // Check if the errorMessage parameter is provided and set the .error-message content accordingly
    if (errorMessage) {
      modalGenericErrorElement.find('.error-message').text(errorMessage);
    }

    modalGenericErrorElement.removeClass('modal-inactive');

    // Close confirmation modal logic on clicking the close button
    modalGenericErrorElement.on('click', '[cb-modal-element="close"]', function () {
      modalGenericErrorElement.remove();
    });
  }

  function showSpinner() {
    $('#spinnerOverlay').show();
  }

  function hideSpinner() {
    $('#spinnerOverlay').hide();
  }

  /*-----------------------------------------------------------*/
  /* Account Boxes                                             */
  /*-----------------------------------------------------------*/

  $('body').on('click', '[cb-acc-element=open]', function(event){
    $(".lay__box").addClass("inactive")
    $(this).parents("[cb-acc-element=box]").addClass("active")
    $(this).parents("[cb-acc-element=box]").find("input").eq(0).focus()
  })

  $('body').on('click', '[cb-acc-element=close]', function(event){
    $(".lay__box").removeClass("active inactive")
    $(".lay__box").find("input").blur()
  })


});
/*----------------------------------------------------------------------------------------*/
/* END - MANAGE ADDRESSES                                                                 */
/*----------------------------------------------------------------------------------------*/


// Handle submit FORM + validations
$(document).ready(function () {

  // Validate instant input filed
  var typingTimer;
  const doneTypingInterval = 300;

  $(document).on('input', '#rendered-manage-address .utl-ipt__field', function () {
    clearTimeout(typingTimer);
    var field = $(this);
    typingTimer = setTimeout(function () {
      var addressType = $('.type_address').attr('type_address');

      $.ajax({
        url: '/account/validate-address',
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify({
          addressType: addressType,
          fieldName: field.attr('name'),
          fieldValue: field.val(),
        }),
        success: function (data) {
          var errorMessage = data.errors ? data.errors[0] : '';
          setFormError(field.attr('name'), errorMessage);
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.error('Error validating field:', textStatus, errorThrown);
        }
      });
    }, doneTypingInterval);
  });


  function setFormError(fieldName, errorMessage) {
    var formField = $('[name="' + fieldName + '"]');
    var errorElement = formField.closest('.utl-ipt__block').find('.utl-ipt__info__text');

    if (!errorMessage) {
      errorElement.empty();
      formField.closest('.utl-ipt__block').attr('cb-input-status', 'correct');
    } else {
      errorElement.html(errorMessage);
      setStyleErrors();
    }
  }


/*-----------------------------------------------------------*/
/* START - NOTIFICATION ACCESSORIES MODAL                     */
/*-----------------------------------------------------------*/
$(document).ready(() => {
  $('.add-accessory-button, .cart-submit-btn').click(function () {
    var modal = $("#modal-notification-checkout");
    var closeButton = modal.find("[cb-modal-element='close']");
    var noButton = modal.find(".utl__button.neg-outline");

    if ($(".notification__list").length > 0) {
      $(modal).removeClass("modal-inactive").addClass("modal-active");

      closeButton.click(function() {
        modal.removeClass("modal-active").addClass("modal-inactive");
      });
      noButton.click(function() {
        modal.removeClass("modal-active").addClass("modal-inactive");
      });
      if ($(this).data('next') == 'checkout') {
        $('.cart-next-btn').removeClass('hidden_element');
      } else if (!$('.cart-next-btn').hasClass('hidden_element')) {
        $('.cart-next-btn').addClass('hidden_element');
      }
    } else {
      window.location.href = $(this).attr("url");
    }
  });
});
/*-----------------------------------------------------------*/
/* END - NOTIFICATION ACCESSORIES MODAL                       */
/*-----------------------------------------------------------*/

});

/*-----------------------------------------------------------*/
/* START - NOTIFICATION ADDRESS BLOCKED MODAL + SEND MAIL     */
/*-----------------------------------------------------------*/
$(document).ready(() => {
  $('[data-requires-notification-custom]').requireNotificationCustom();

  $(document).ready(function() {
    var modal = $("#modal-address");
    var closeButton = modal.find("[cb-modal-element='close']");

    closeButton.click(function() {
      modal.removeClass("modal-active").addClass("modal-inactive");
    });

  });
});

$.fn.extend({
  requireNotificationCustom() {
    this.each((idx, el) => {
      $(el).on('click', (evt) => {
        evt.preventDefault();
        $(`#modal-address`).removeClass("modal-inactive").addClass("modal-active");
      });
    });
  },
});

function openMail() {

  var mailtoLink = 'mailto:';
  window.location.href = mailtoLink;
}

document.addEventListener('DOMContentLoaded', function() {
  document.addEventListener('click', function(event) {
      if (event.target && event.target.id === 'email-button') {
          openMail();
      }
  });
});


/*-----------------------------------------------------------*/
/* END - NOTIFICATION ADDRESS BLOCKED MODAL + SEND MAIL     */
/*-----------------------------------------------------------*/



/*-----------------------------------------------------------*/
/* START - REGISTER PREFIX OVERWRITE                            */
/*-----------------------------------------------------------*/
function addLanguageCode() {
  $("[cb-input-type=phone-reea]").each(function () {
    var curr = $(this).find("input").val();
    var currFormatted = formatPhoneNumber(curr);

    if (currFormatted.slice(0, -1) === curr) {
      currFormatted = currFormatted.slice(0, -1);
    }

    if (currFormatted.slice(-1) === " ") {
      currFormatted = currFormatted.slice(0, -1);
    }

    $(this).find("input").val(currFormatted);
  });
}

function formatPhoneNumber(inputString) {
  var digitsOnly = inputString.replace(/\D/g, '');
  var formattedNumber = '';
  for (var i = 0; i < digitsOnly.length; i++) {
    formattedNumber += digitsOnly[i];
    if (i === 1 || i === 4 || i === 6) {
      formattedNumber += ' ';
    }
  }
  return formattedNumber;
}
$("[cb-input-type=phone-reea]").on("input change", function() {
  addLanguageCode()
})
addLanguageCode();
/*-----------------------------------------------------------*/
/* END - REGISTER PREFIX OVERWRITE                            */
/*-----------------------------------------------------------*/


/*-----------------------------------------------------------*/
/* START - SIDENAV                                           */
/*-----------------------------------------------------------*/

$(document).ready(function() {
  function sidenavStylesCustom() {
      $(".sidenav__list, .sidenav__item").removeClass("open open-child");
      $(".sidenav__link.active").parents(".sidenav__item").addClass("open");
      $(".sidenav__link.active").parents(".sidenav__item").parents(".sidenav__list").addClass("open-child");
      $(".sidenav__link.active").parents(".sidenav__list").removeClass("utl__hidden");
  }

  function showChildTaxonMobile(element,event){
      event.preventDefault();
      var $element = $(element);

      if ($element.parent().parent().hasClass('open') && $element.parent().parent().parent().hasClass('open-child') || $element.hasClass('no-child')) {
          window.location.href = $element.attr('href');
      } else {
          $element.parent().parent().addClass('open');
          $element.parent().parent().parent().addClass('open-child');
      }
  }

  function closeChildTaxonMobile(element){
      var $element = $(element);
      // Remove the <ul> element within the div
      var divElement = $element.parent().parent();
      var ulElement = divElement.find('ul');

      if (ulElement.length > 0) {
          $element.parent().parent().removeClass('open');
          $element.parent().parent().parent().removeClass('open-child');
      }
  }

  sidenavStylesCustom();

  $(document).on('click', '[data-action]', function(event) {
      var action = $(this).data('action');
      switch (action) {
          case 'showChildTaxonMobile':
              showChildTaxonMobile(this, event);
              break;
          case 'closeChildTaxonMobile':
              closeChildTaxonMobile(this);
              break;
      }
  });
});


/*-----------------------------------------------------------*/
/* END - SIDENAV                                           */
/*-----------------------------------------------------------*/
$(document).ready(function(){
  $( ".menu-dropdown-css" ).each(function( index ) {
    let itemCount = $(this).find('a').length
    if (itemCount == 3) {
      $('.menu-dropdown-css').css("top", "-50%");
    }else if(itemCount == 4) {
      $('.menu-dropdown-css').css("top", "-150%");
    } else if(itemCount == 5) {
      $('.menu-dropdown-css').css("top", "-250%");
    } else if(itemCount == 6) {
      $('.menu-dropdown-css').css("top", "-450%");
    } else if(itemCount == 7) {
      $('.menu-dropdown-css').css("top", "-650%");
    }else{
      $('.menu-dropdown-css').css("top", "0");
    }
  });

});

/*-----------------------------------------------------------*/
/* START - PROGRESS BAR SHOP THE LOOK                        */
/*-----------------------------------------------------------*/

// $(document).ready(function() {
//   var postsPerPage = 10;
//   var totalDisplayedPosts = 0;
//   showPosts();

//   $('a[cb-load-element="more-button-posts"]').on('click', function(e) {
//       e.preventDefault();
//       showPosts();
//   });

//   function showPosts() {
//       var totalPosts = $('.stl__item').length;
//       var remainingPosts = totalPosts - totalDisplayedPosts;
//       var postsToShow = Math.min(remainingPosts, postsPerPage);

//       $('.stl__item').hide().slice(0, totalDisplayedPosts + postsToShow).show();

//       totalDisplayedPosts += postsToShow;

//       var progress = (totalPosts > 0) ? (totalDisplayedPosts / totalPosts) * 100 : 0;
//       $('#utl-load__progress-bar-posts').css('width', Math.min(progress, 100) + '%');

//       $('#utl-load__current_posts').text(totalDisplayedPosts + ' von ' + totalPosts);

//       if (totalDisplayedPosts >= totalPosts) {
//           $('a[cb-load-element="more-button-posts"]').hide();
//       }
//   }
// });

/*-----------------------------------------------------------*/
/* END - PROGRESS BAR SHOP THE LOOK                        */
/*-----------------------------------------------------------*/

$(document).ready(() => {
  $('body').on('click', '#load_more_products_button a', function(e){
    e.preventDefault();
    var loadMoreButton = $(this);
    var form = $('#wf-form-Product-filter');
    let data = form.serializeArray();
    $.ajax({
      url: loadMoreButton.attr('href'),
      type: 'GET',
      data: data,
      success: function(data) {
        var parsedData = $(data.htmlContent);
        //take all the products from the page rendered in response
        var layBoxes = parsedData.find('div.prd-itm__wrapper');
        var targetDiv = $('#previous_data_request');
        //attach the products to older orders
        targetDiv.append(layBoxes);

        //display or not the new button
        var loadMoreButtonResponse = parsedData.find('#load_more_products_button');
        $('#load_more_products_button').html(loadMoreButtonResponse.html());

        //display number x from x orders
        var nbOrdersDisplayed = parsedData.find('.utl-load__current');
        $('.utl-load__current').html(nbOrdersDisplayed.html());

        //display progress of the red bar
        var progressBar = parsedData.find('.utl-load__progress');
        $('.utl-load__progress').html(progressBar.html());
      },
      error: function() {
        // Handle AJAX error
      }
    });
  });
});

//NUMBER GRATER THEN 1
$(document).ready(function() {

  $('body').on('change', '.value_grater_then_1', function(event){
    var value = parseInt($(this).val());
    // If the value is less than 1, set it to 1
    if (value < 1) {
      $(this).val(1);
    }
  });
});

/*-----------------------------------------------------------*/
/* Amount Selector                                           */
/*-----------------------------------------------------------*/

$("[cb-amount-element=select], [cb-amount-element=input]").on("change input", function(e) {
  var val = parseInt($(this).val())

  if (val === "" || val === null) {return}

  var group = $(this).parents("[cb-amount-element=amount]")
  var select = group.find("[cb-amount-element=select]")
  var input = group.find("[cb-amount-element=input]")

  input.val(val)

  if (val >= 1 && !group.hasClass("cb-input-mode")) {
    group.addClass("cb-input-mode")
    input.focus().select()
  }

  amountInputSizer()
})

// add default values to hidden Input

$("[cb-amount-element=amount]").each(function() {
  var val = $(this).find("[cb-amount-element=select]").val()
  $(this).find("[cb-amount-element=input]").val(val)
})


$("[cb-amount-element=type]").on("change input", function(e) {
  console.log("hit")
  amountInputSizer()
})


$("[cb-amount-element=input]").on("focusout", function() {
  var val = parseInt($(this).val())
  var group = $(this).parents("[cb-amount-element=amount]")
  var select = group.find("[cb-amount-element=select]")

  if (select.find(`option[value='${val}']`).length) {
    select.focus()
    group.removeClass("cb-input-mode")
    select.val(val)
  }
  else if ($(this).val() === "" || val <= 0) {
    select.focus()
    group.removeClass("cb-input-mode")
    select.val("1")
  }

  amountInputSizer()
})

function amountInputSizer() {
  $("[cb-amount-element=amount], [cb-amount-element=type]").each(function() {
    var target = $(this).find("[cb-amount-target]")
    var val = $(this).find("[cb-amount-target]").val()

    if (target.is("select")) {
      val = $(this).find("[cb-amount-target] option:selected").text()
    }

    var dummy = $(this).find("[cb-amount-element=dummy]")
    var w = dummy.text(val).width()

    //if (w < 1) {return}

    $(this).attr("style", `--content-width: ${w}px`)
  })
}
amountInputSizer()

/*-----------------------------------------------------------*/
/* BUTTON RESIZE                                             */
/*-----------------------------------------------------------*/

$(document).ready(function() {
  function updateInfoActionWidth() {
      if ($(window).width() <= 479 && $('[cb-status-state="available"].utl-stat__wrapper').length > 0) {
          $('.prd__info__action').addClass('responsive-width');
      } else {
          $('.prd__info__action').removeClass('responsive-width');
      }
  }
  updateInfoActionWidth();
  $(window).resize(updateInfoActionWidth);
});

/*-----------------------------------------------------------*/
/* LOADING ICON FOR CHECKOUT BUTTON                          */
/*-----------------------------------------------------------*/

document.addEventListener('DOMContentLoaded', (event) => {
  const checkoutButton = document.getElementById('checkout-button');
  
  if (checkoutButton) {
      const loadingIcon = checkoutButton.querySelector('.loading-icon');
      const buttonText = checkoutButton.querySelector('span:first-child');

      checkoutButton.addEventListener('click', function(event) {
          event.preventDefault();
          
          loadingIcon.classList.remove('hidden');
          buttonText.classList.add('hidden');
          
          checkoutButton.classList.add('disabled');
          checkoutButton.setAttribute('disabled', 'true');

          window.location.href = checkoutButton.getAttribute('url');
      });
  }
});

/*-----------------------------------------------------------*/
/* DYNAMIC POSITION TOOLTIPS                                 */
/*-----------------------------------------------------------*/

document.addEventListener('DOMContentLoaded', function() {
  document.querySelectorAll('.utl-points__point').forEach(function(point) {
      var tooltipWrapper = point.querySelector('.utl-points__tooltip-wrapper');
      var position = point.getAttribute('data-tooltip-position');
      
      // dynamic positioning
      var pointRect = point.getBoundingClientRect();
      var tooltipRect = tooltipWrapper.getBoundingClientRect();
      var tooltipTopPosition;

      if (position.includes('top')) {
          tooltipTopPosition = -(tooltipRect.height + 2) + 'px'; 
          tooltipWrapper.style.top = tooltipTopPosition;
          tooltipWrapper.style.bottom = '';
      } else {
          tooltipWrapper.style.top = pointRect.height + 45 + 'px'; 
          tooltipWrapper.style.bottom = '';
      }

      if (position.includes('left')) {
          tooltipWrapper.style.left = '';
          tooltipWrapper.style.right = '0';
          tooltipWrapper.style.transform = '';
      } else if (position.includes('right')) {
          tooltipWrapper.style.left = '0';
          tooltipWrapper.style.right = '';
          tooltipWrapper.style.transform = '';
      } else {
          tooltipWrapper.style.left = '50%';
          tooltipWrapper.style.right = '';
          tooltipWrapper.style.transform = 'translateX(-50%)';
      }
  });
});

