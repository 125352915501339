/*-----------------------------------------------------------*/
/* Quicklinks                                                */
/*-----------------------------------------------------------*/


var updateBoxTimeout

$("[cb-quicklink-element]").click(function() {
  var type = $(this).attr("cb-quicklink-element")

  if (type === "remove") {return}

  $("html").addClass("active-updatebox")

  clearTimeout(updateBoxTimeout)

  updateBoxTimeout = setTimeout(function() {
    $("html").removeClass("active-updatebox")
  }, 5000)
})

$(".update-box").hover(function() {
  clearTimeout(updateBoxTimeout)
}, function() {
  updateBoxTimeout = setTimeout(function() {
    $("html").removeClass("active-updatebox")
  }, 1000)
})
$("[cb-updatebox-element=close]").click(function() {
  $("html").removeClass("active-updatebox")
})





/*-----------------------------------------------------------*/
/* Navigation: Language Picker                               */
/*-----------------------------------------------------------*/

$(".nav-lg__toggle").click(function() {
  $(this).parents(".nav-lg").toggleClass("active")
})



/*-----------------------------------------------------------*/
/* Navigation: Scroll Lock                                   */
/*-----------------------------------------------------------*/


const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;

//$("input.search__input").attr("readonly", "false")



function toggleScrollNav() {
  var targetElement = document.querySelector('body')

  if (($("html").is(".locked") || $(".nav-dd.active").length) && $(window).width() < 992) { /* , .search-active */
    disableBodyScroll(targetElement, {
      allowTouchMove: el => {
        while (el && el !== document.body) {
          if (el.getAttribute('body-scroll-lock-ignore') !== null) {
            return true;
          }

          el = el.parentElement;
        }
      },
    });
  }
  else {
    enableBodyScroll(targetElement)
  }
}

$(window).on("resize focusin", toggleScrollNav)
$(window).on("scroll", function() {
  if ($("html").hasClass("search-active")) {
    //$(window).scrollTop(0)
  }
})



/*-----------------------------------------------------------*/
/* Sidenav                                                   */
/*-----------------------------------------------------------*/

// function sidenavStyles() {
//   $(".sidenav__list, .sidenav__item").removeClass("open open-child")
//   $(".sidenav__list").parents(".sidenav__item").addClass("open")
//   $(".sidenav__list").parents(".sidenav__item").parents(".sidenav__list").addClass("open-child")
//   $(".sidenav__item.open").parents(".sidenav__item.open").addClass("open-child")
//   sidenavSetSticky()
// }

// function sidenavSetSticky() {
//   var sb = $(".sidenav")

//   if ($(window).height() > sb.height()) {
//     sb.addClass("sticky")
//   }
//   else {
//     sb.removeClass("sticky")
//   }
// }

// $(window).on("resize focusin", sidenavSetSticky)
// $(document).ready(sidenavSetSticky);
// sidenavSetSticky()
// sidenavStyles()



/*-----------------------------------------------------------*/
/* Navigation: Search                                        */
/*-----------------------------------------------------------*/

// Open window

$(".search__input").on("focus", function () {

  console.log("add class")
  $("html").addClass("search-active");

  if (!$("html").hasClass("nav-fixed")) {
    $(window).scrollTop(0)
  }

  toggleScrollNav()
  /*
  $(".search-dropdown").css({
    "height": window.visualViewport.height + "px",
    "min-height": "0",
    "max-height": window.visualViewport.height + "px",
  })*/
})

/*
window.addEventListener('resize', () => {
  $("html").attr("style", `--window-height: ${window.visualViewport.height}px`)

  if (!window.visualViewport || !$("html").is(".search-active")) {
    return
  }
})*/

$(".search__input").on("input", function() {searchClearState()})


function searchClearState(clear) {
  if (clear) {$(".search__input").val("")}

  var val = $(".search__input").val()

  if (val === "") {
    $(".search-box").removeClass("active-search")
  }
  else {
    $(".search-box").addClass("active-search")
  }
}


// Clear Input

$(".search__clear").click(function() {
  $(".search__input").val("").focus()
  searchClearState()
})

// Close window

$("body").click(function(e) {
  var target = $(e.target)


  if (!target.is(".search-dropdown, .search-dropdown__block, .search__last") && (target.hasClass("search-box") || target.hasClass("search__input") || target.parents(".search-box, .search__input").length || !$("html").hasClass("search-active"))) {return}

  e.preventDefault()
  console.log("remove")
  $("html").removeClass("search-active");
  toggleScrollNav()
  searchClearState(true)
})




/*-----------------------------------------------------------*/
/* Navigation Stickyness                                     */
/*-----------------------------------------------------------*/

let scrollPos = $(document).scrollTop();

function navVisiblityState(instantly) {
  if ($("html").hasClass("search-active")) {return}

  let notification = $(".nav-notification .navbar__notification").height() ?? 0

  let threshold = $(".navbar").height();
  let subnav = $(".navbar__bottom").height();
  let currPos = $(document).scrollTop();

  if (currPos <= threshold) {
    if (!$("html").hasClass("nav-fixed")) {
      var os = threshold - subnav + 20
      $(".sidenav").attr("style", `--top-offset: ${os}px`);
    }

    if ($(document).scrollTop() > 0 + notification) {
      return;
    }

    if ($("html").hasClass("locked")) {return}

    $("html").removeClass("nav-fixed scrolled-start");
    return;
  }

  if (scrollPos > currPos) {
    // up
    $("html").removeClass("nav-folded");
    var os = threshold + 20
    $(".sidenav").attr("style", `--top-offset: ${os}px`);
  } else if (scrollPos < currPos) {
    // down
    $("html").addClass("nav-folded");
    var os = threshold - subnav + 20
    $(".sidenav").attr("style", `--top-offset: ${os}px`);
  }

  if (instantly) {
    if ($(document).scrollTop() <= 0) {
      return;
    }

    $("html").addClass("scrolled-start");

    $("html").addClass("nav-fixed");
    var os = threshold + 20
    $(".sidenav").attr("style", `--top-offset: ${os}px`);

  } else {
    setTimeout(function () {
      if ($(document).scrollTop() <= 0) {
        return;
      }
      $("html").addClass("nav-fixed");
    }, 100);
  }

  scrollPos = currPos;
}

$(window).scroll(function () {
  navVisiblityState();
});



$(document).ready(function () {
  navVisiblityState(true);
});
navVisiblityState(true);




/*-----------------------------------------------------------*/
/* Navigation Menu                                           */
/*-----------------------------------------------------------*/

$(".navbar__menu").click(function() {
  if (!$("html").hasClass("nav-fixed")) {
    $(window).scrollTop(0)
  }
  $("html").toggleClass("nav-open")

  if ($("html").hasClass("nav-open")) {
    $(".nav-open .navbar__bottom").scrollTop(0)
    $("html").addClass("locked")
    toggleScrollNav()
  }
  else {
    $("html").removeClass("locked")
    toggleScrollNav()
  }
})




/*-----------------------------------------------------------*/
/* Navigation Dropdowns                                      */
/*-----------------------------------------------------------*/

/* Dropdown */
// $(".nav-ml-dd__toggle").click(function (e) {
//   var index = $(this).parent(".nav-ml-dd__dropdown").index();
//
//   if (
//     $(e.target).hasClass(".nav-ml-dd__options__wrapper") ||
//     ($(e.target).parents(".nav-ml-dd__options__wrapper").length &&
//       $(".nav-ml-dd__dropdown").eq(index).hasClass("active"))
//   ) {
//     return;
//   }
//
//   toggleNavDD(index);
// });

$('body').on('click', '.nav-ml-dd__toggle', function(e){
  var index = $(this).parent(".nav-ml-dd__dropdown").index();

  if (
      $(e.target).hasClass(".nav-ml-dd__options__wrapper") ||
      ($(e.target).parents(".nav-ml-dd__options__wrapper").length &&
          $(".nav-ml-dd__dropdown").eq(index).hasClass("active"))
  ) {
    return;
  }

  toggleNavDD(index);
});

function toggleNavDD(index) {
  var curr = $(".nav-ml-dd__dropdown").eq(index);
  var state = curr.hasClass("active"); // allows to close all 1

  $(".nav-ml-dd__dropdown").removeClass("active");
  $(".nav-ml-dd__list").css("height", "0");

  if (state) {
    return;
  } // allows to close all 2

  navDdArrowPos()

  curr.addClass("active");
  curr
    .find(".nav-ml-dd__list")
    .css("height", curr.find(".nav-ml-dd__list-wrapper").outerHeight() + "px");

  toggleScrollNav()
}

/* Windows */

$('body').on('click', "[cb-window-trigger]", function(e){
  var name = $(this).attr("cb-window-trigger");
  var nameIndex = $(this).index(`[cb-window-trigger=${name}]`);
  var target = $(`[cb-window-target=${name}]`).eq(nameIndex);
  var state = target.hasClass("active");



  $(this).parents("[cb-window-target]").addClass("utl--exclude");

  $(`[cb-window-target]:not(.utl--exclude)`).removeClass("active");

  $(this).parents("[cb-window-target]").removeClass("utl--exclude");

  if (state) {
    return;
  }

  navDdArrowPos()
  console.log("add")
  target.addClass("active");
  toggleScrollNav()
});
// $("[cb-window-trigger]").click(function () {
  // var name = $(this).attr("cb-window-trigger");
  // var nameIndex = $(this).index(`[cb-window-trigger=${name}]`);
  // var target = $(`[cb-window-target=${name}]`).eq(nameIndex);
  // var state = target.hasClass("active");
  //
  //
  //
  // $(this).parents("[cb-window-target]").addClass("utl--exclude");
  //
  // $(`[cb-window-target]:not(.utl--exclude)`).removeClass("active");
  //
  // $(this).parents("[cb-window-target]").removeClass("utl--exclude");
  //
  // if (state) {
  //   return;
  // }
  //
  // navDdArrowPos()
  // console.log("add")
  // target.addClass("active");
  // toggleScrollNav()
// });

$("body").click(function (e) {
  if (!$("[cb-window-target].active").length) {
    return;
  }

  //if ($(e.target).parents("[cb-quicklink-element]").length || $(this).attr("cb-quicklink-element")) {return}

  var targetEl = $("[cb-window-target].active").eq(-1);
  var targetName = targetEl.attr("cb-window-target");

  if (!$(e.target).parents(`[cb-window-target=${targetName}], [cb-window-trigger=${targetName}]`).length && !$(e.target).is(`[cb-window-target=${targetName}], [cb-window-trigger=${targetName}]`)) {
    e.preventDefault();

    console.log("remove")
    console.log($(e.target))
    targetEl.removeClass("active");
  }

  toggleScrollNav()
});


function navDdArrowPos() {
  $("[cb-window-target]").each(function() {
    var name = $(this).attr("cb-window-target")
    var arrow = $(this).find(".nav-dd__arrow")

    if (!arrow.length) {return}

    var arrowZero = $(this).offset().left + (arrow.width() / 2)

    var link = $(`[cb-window-trigger=${name}]`).eq(0)
    var linkZero = link.offset().left + (link.width() / 2)

    var offset = linkZero - arrowZero

    arrow.css("left", offset + "px")
  })
}





/*-----------------------------------------------------------*/
/* Amount Selector                                           */
/*-----------------------------------------------------------*/

$("[cb-amount-element=select], [cb-amount-element=input]").on("change input", function(e) {
  var val = parseInt($(this).val())

  if (val === "" || val === null) {return}

  var group = $(this).parents("[cb-amount-element=amount]")
  var select = group.find("[cb-amount-element=select]")
  var input = group.find("[cb-amount-element=input]")

  input.val(val)

  if (val >= 5 && !group.hasClass("cb-input-mode")) {
    group.addClass("cb-input-mode")
    input.focus().select()
  }

  amountInputSizer()
})

// add default values to hidden Input

$("[cb-amount-element=amount]").each(function() {
  var val = $(this).find("[cb-amount-element=select]").val()
  $(this).find("[cb-amount-element=input]").val(val)
})


$("[cb-amount-element=type]").on("change input", function(e) {
  console.log("hit")
  amountInputSizer()
})


$("[cb-amount-element=input]").on("focusout", function() {
  var val = parseInt($(this).val())
  var group = $(this).parents("[cb-amount-element=amount]")
  var select = group.find("[cb-amount-element=select]")

  if (select.find(`option[value='${val}']`).length) {
    select.focus()
    group.removeClass("cb-input-mode")
    select.val(val)
  }
  else if ($(this).val() === "" || val <= 0) {
    select.focus()
    group.removeClass("cb-input-mode")
    select.val("1")
  }

  amountInputSizer()
})



function amountInputSizer() {
  $("[cb-amount-element=amount], [cb-amount-element=type]").each(function() {
    var target = $(this).find("[cb-amount-target]")
    var val = $(this).find("[cb-amount-target]").val()

    if (target.is("select")) {
      val = $(this).find("[cb-amount-target] option:selected").text()
    }

    var dummy = $(this).find("[cb-amount-element=dummy]")
    var w = dummy.text(val).width()

    //if (w < 1) {return}

    $(this).attr("style", `--content-width: ${w}px`)
  })
}
amountInputSizer()



/*-----------------------------------------------------------*/
/* Swipers                                                   */
/*-----------------------------------------------------------*/


var globalSwipers = [];

function addSwipers() {
  var swiperNames = [];
  $("[cb-swiper-element=wrapper]").addClass("cb-swiper-wrapper")
  $("[cb-swiper-element=item]").addClass("cb-swiper-item")


  $("[cb-swiper-group]").each(function () {
    var el = $(this);
    var group = el.attr("cb-swiper-group");
    var s = "[cb-swiper-group=" + group + "]";

    var type = $(this).attr("cb-swiper-type")

    if ($.inArray(group, swiperNames) !== -1) {
      console.log("Group named " + group + " already exists");
      return;
    }

    swiperNames.push(group);

    var container = s + " [cb-swiper-element=container]";
    var prev = s + " [cb-swiper-element=prev]";
    var next = s + " [cb-swiper-element=next]";
    var controls = s + " [cb-swiper-element=controls]";
    var pagination = s + " [cb-swiper-element=pagination]";


    var swiperObject = {
      wrapperClass: "cb-swiper-wrapper",
      slideClass: "cb-swiper-item",
      navigation: {
        prevEl: prev,
        nextEl: next,
        disabledClass: "cb-swiper-disabled",
      },
      slidesPerView: "auto",
      direction: "horizontal",
      spaceBetween: 0,
      speed: 400,
      watchOverflow: true,
      grabCursor: true,
      loop: false,
      centeredSlides: false,
      pagination: {
        bulletClass: "cb-swiper-pagination",
        bulletActiveClass: "cb-active",
        el: pagination,
        clickable: true,
        dynamicBullets: false,
      },
      on: {
        lock: function () {
          $(controls).addClass("cb-swiper-hidden");
        },
        unlock: function () {
          $(controls).removeClass("cb-swiper-hidden");
        },
      },
    }

    if (type === "hero") {
      swiperObject.loop = true;
      swiperObject.centeredSlides = true;
      swiperObject.spaceBetween = 20;
    }


    var dummySwiper = new Swiper(container, swiperObject);

    globalSwipers.push(dummySwiper);
  });
}

addSwipers();



/*-----------------------------------------------------------*/
/* Inputs                                                    */
/*-----------------------------------------------------------*/


/* add country code automatically */

$("[cb-input-type=phone]").on("input change", function() {
  addLanguageCode()
})

function addLanguageCode() {
  $("[cb-input-type=phone]").each(function() {
    var vorwahl = $(this).find("select").val() + " "
    var curr = $(this).find("input").val().split(vorwahl)[1] ?? ""

    currFormatted = formatPhoneNumber(curr)

    if (currFormatted.slice(0,-1) === curr) {
      currFormatted = currFormatted.slice(0,-1)
    }

    if (currFormatted.slice(-1) === " ") {
      currFormatted = currFormatted.slice(0,-1)
    }


    $(this).find("input").val(vorwahl + currFormatted)
  })
}

function formatPhoneNumber(inputString) {
  var digitsOnly = inputString.replace(/\D/g, '');
  var formattedNumber = '';
  for (var i = 0; i < digitsOnly.length; i++) {
    formattedNumber += digitsOnly[i];
    if (i === 1 || i === 4 || i === 6) {
      formattedNumber += ' ';
    }
  }
  return formattedNumber;
}

addLanguageCode()



/* Password Toggle */


$("[cb-password-group] .utl-ipt__info__icon__wrapper").click(function () {
  var state = $(this).parents("[cb-password-group]").attr("cb-input-password")
  var groupName = $(this).parents("[cb-password-group]").attr("cb-password-group");
  var groupItem = $(`[cb-password-group=${groupName}]`)

  console.log("click", state)

  if (state === "encrypted") {
    groupItem.find("input").attr("type", "text");
    groupItem.attr("cb-input-password", "decrypted");
  } else {
    groupItem.find("input").attr("type", "password");
    groupItem.attr("cb-input-password", "encrypted");
  }
});




$("[cb-password-group] input").on("input change", function() {
  var group = $(this).parents("[cb-password-group]").attr("cb-password-group")

  if ($(this).index(`[cb-password-group=${group}] input`) !== 0) {return}

  var val = $(this).val()
  var passWrapper = $(`.pass-strength[cb-password-group=${group}]`)
  var passItems = passWrapper.find(".pass-strength__item")

  var regexLowerUpper = /(?=.*[a-z])(?=.*[A-Z])/;
  var regexSpecialChars = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?0-9]/;


  passItems.removeClass("pass")

  if (regexLowerUpper.test(val)) {
    passItems.eq(0).addClass("pass")
  }
  if (regexSpecialChars.test(val)) {
    passItems.eq(1).addClass("pass")
  }
  if (val.length >= 10) {
    passItems.eq(2).addClass("pass")
  }
})




/*-----------------------------------------------------------*/
/* Accordions                                                */
/*-----------------------------------------------------------*/


$(".utl-acc__toggle").click(function(e) {
  var target = $(e.target)
  var parent = $(this).parents(".utl-acc")
  var state = parent.hasClass("cb-active")

  if (target.hasClass("nav-ml-dd__options__wrapper") || target.parents(".nav-ml-dd__options__wrapper").length) {return}

  var groupType = $(this).parents("[cb-accordeon-group]").attr("cb-accordeon-type")

  if (groupType === "exclusive") {
    $(this).parents("[cb-accordeon-group]").find(".cb-active").removeClass("cb-active")

    parent.addClass("cb-active")
  }
  else {
    $(this).parents(".utl-acc").toggleClass("cb-active")
  }
  amountInputSizer()
  amountInputSizer()
  amountInputSizer()
  amountInputSizer()
})


$(".footer__nav__header").click(function(e) {
  $(this).parents(".footer__nav").toggleClass("cb-active")
})



/*-----------------------------------------------------------*/
/* Account Boxes                                             */
/*-----------------------------------------------------------*/


$("[cb-acc-element=open]").click(function() {
  $(".lay__box").addClass("inactive")
  $(this).parents("[cb-acc-element=box]").addClass("active")
  $(this).parents("[cb-acc-element=box]").find("input").eq(0).focus()
})

$("[cb-acc-element=close]").click(function() {
  $(".lay__box").removeClass("active inactive")
  $(".lay__box").find("input").blur()
})




/*-----------------------------------------------------------*/
/* Filter Dropdowns                                          */
/*-----------------------------------------------------------*/

$(".flt-ipt__toggle").click(function() {
  var group = $(this).parents(".flt-ipt__group")
  var aa = group.hasClass("flt-ipt-active")

  $(".flt-ipt__group").removeClass("flt-ipt-active")

  if (aa) {return}

  group.addClass("flt-ipt-active")
})

$(".flt-ipt__list__button-wrapper a, .flt-ipt__close").click(function() {
  $(".flt-ipt__group").removeClass("flt-ipt-active")
})


/* Rangeslider */

function setRangeSliders() {
  $(".flt-ipt__slider").each(function() {
    var input = $(this).find(".flt-ipt__slider-ipt__input")
    var min = parseFloat($(this).attr("cb-range-min")) ?? 0
    var max = parseFloat($(this).attr("cb-range-max")) ?? 1000
    var from = parseFloat($(this).attr("cb-range-from")) ?? min
    var to = parseFloat($(this).attr("cb-range-to")) ?? max
    var step = parseFloat($(this).attr("cb-range-step")) ?? 10
    var slider = $(this).find(".flt-ipt__slider-dummy")


    if (slider.data("ionRangeSlider") !== undefined) {
      var instance = slider.data("ionRangeSlider")
      instance.update({
        min: min,
        max: max,
        step: step,
        from: from,
        to: to,
      });
      return
    }



    slider.ionRangeSlider({
      skin: "flat",
      type: "double",
      min: min,
      max: max,
      step: step,
      from: from,
      to: to,
      grid: false,
      prettify_enabled: false,
      hide_min_max: true,
      hide_from_to: true,
      force_edges: true,
      onStart: updateInputs,
      onUpdate: updateInputs,
      onChange: updateInputs,
      onFinish: updateInputs
    });

    var instance = slider.data("ionRangeSlider")

    function updateInputs(data) {
      from = data.from;
      to = data.to;

      input.eq(0).val(from);
      input.eq(1).val(to);
    }

    input.on("change", function (e) {
      var val = $(this).val();
      var type = $(this).attr("cb-range-element")

      // validate

      if (val < min) {
        val = min
        $(this).val(min)
      }
      else if (val > max) {
        val = max
      }
      else if (type === "to" && val < instance.result.from) {
        val = instance.result.from
      }
      else if (type === "from" && val > instance.result.to) {
        val = instance.result.to
      }

      $(this).val(val)

      instance.update({
        [type]: val
      });
    })
  })
}




/* Align Filters to prevent Overflow */

function filterAlignment() {
  $(".flt-ipt__list").each(function() {
    var ep = $(this).parents(".flt-ipt__group").offset().left
    var ew = $(this).outerWidth()
    var bw = $("body").width()

    if (ep + ew > bw - 40) {
      $(this).addClass("align-right")
    }
    else {
      $(this).removeClass("align-right")
    }
  })
}



function triggerFilters() {
  if (!$(".flt-ipt__group").length) {return}

  setRangeSliders()
  $(window).on("resize focusin", filterAlignment)
  $(document).ready(filterAlignment);
  filterAlignment()
}
triggerFilters()




/*-----------------------------------------------------------*/
/* Breadcrumbs                                               */
/*-----------------------------------------------------------*/


function intercomHider() {
  if (!$(".intercom-lightweight-app").length) {return}

  if ($(window).scrollTop() + $(window).height() >= $(document).height() - 100) {
    $(".intercom-lightweight-app").addClass("intercom-hide")
  }
  else {
    $(".intercom-lightweight-app").removeClass("intercom-hide")
  }

}


$(window).on("scroll resize focusin", intercomHider)
$(document).ready(intercomHider);
intercomHider()


/*-----------------------------------------------------------*/
/* Breadcrumbs                                               */
/*-----------------------------------------------------------*/

function scrollBreadcrumbs() {
  $(".breadcumbs-wrapper").scrollLeft(0)
}


$(document).ready(scrollBreadcrumbs())
scrollBreadcrumbs()




/*-----------------------------------------------------------*/
/* Notification box                                          */
/*-----------------------------------------------------------*/


$("[cb-notification-element=close]").click(function() {
  $(this).parents("[cb-notification-element=item]").remove()
})


/*-----------------------------------------------------------*/
/* Navigation: Notification                                  */
/*-----------------------------------------------------------*/

$("[cb-navnotification-element=close]").click(function() {
  document.cookie = `nav-notification=dismissed; path=/`
  $("html, body").removeClass("nav-notification")
  $(".navbar__notification").remove()
})



/*-----------------------------------------------------------*/
/* Footer Year                                               */
/*-----------------------------------------------------------*/


$("[cb-year-element]").text(new Date().getFullYear());



/*-----------------------------------------------------------*/
/* Tabs Scroll Position                                      */
/*-----------------------------------------------------------*/


$(".tabs__menu").on("scroll", function() {
  document.cookie = `accounttabspos=${$(this).scrollLeft()}; path=/`
})
$(".subnav__list-wrapper").on("scroll", function() {
  document.cookie = `subnavtabspos=${$(this).scrollLeft()}; path=/`
})


function navTabsPosition() {
  var wrapper = document.getElementsByClassName("subnav__list-wrapper")[0];
  if (wrapper) {
      var pos = getCookie("subnavtabspos") ?? 0;
      wrapper.scrollLeft = pos;
  }
}
$(document).ready(navTabsPosition);
navTabsPosition();

