/*-----------------------------------------------------------*/
/* Sticky menu product page                                     */
/*-----------------------------------------------------------*/
function quickviewDisplay() {
  var product = $("[cb-page-element=product]")
  var section = $("[cb-page-element=product-section]")

  if ((product.length > 0) && (section.length > 0)) {
    var scroll = $(document).scrollTop()
    var prdPos = product.offset().top + product.height()
    var secPos = section.offset().top + section.height() - ($(window).height() / 2)

    if (scroll - prdPos > 0 && scroll - secPos < 0) {
      $("html").addClass("quickview-active")
    }
    else {
      $("html").removeClass("quickview-active")
    }
  }
}

function constantTrigger() {

  quickviewDisplay()
}


$(window).scroll(function() {constantTrigger()})
$(window).resize(function() {constantTrigger()});
$(window).focusin(function() {constantTrigger()});
$(document).ready(function() {constantTrigger()});

constantTrigger()
