import './styles/webflow.css';
import './styles/mk-ag.webflow.css';
import './styles/normalize.css';
import './styles/app.scss';
import './styles/style.css';
// import './styles/style-reea.css'; // not used in custom.js (used for backup)
import './styles/custom.reea.css';
import './styles/scss/main.scss';

import './scripts/js-wishlist';
import './scripts/ionSlider.min.js';
import './scripts/custom.reea';
import './scripts/globalscript';
import './scripts/cms-product-page';
// import './scripts/webflow';

import logo from './images/logo.svg';
import logo_white from './images/logo_white.svg';
import product__image from './images/product__image.png';
import stl_1 from './images/stl-1.jpg';
import stl_2 from './images/stl-2.jpg';
import stl_3 from './images/stl-3.jpg';
import stl_4 from './images/stl-4.jpg';
import stl_5 from './images/stl-5.jpg';
import stl_6 from './images/stl-6.jpg';
import stl_7 from './images/stl-7.jpg';
import stl_8 from './images/stl-8.jpg';
import stl_9 from './images/stl-9.jpg';
import stl_10 from './images/stl-10.jpg';
import deckel from './images/Deckel.png';
import mark_isler from './images/mark_isler.jpg';
import cta__image from './images/cta__image.png';
import tartar from './images/tartar.jpg';
import tomato_pasta from './images/tomato_pasta.jpg';
import honig from './images/honig.jpg';
import Gewurze from './images/Gewurze.jpg';
import Wein from './images/Wein.jpg';
import Sirup from './images/Sirup.jpg';
import Tabletten from './images/Tabletten.jpg';
import Ole from './images/Ole.jpg';
import Gin from './images/Gin.jpg';
import Whisky from './images/Whisky.jpg';
import Creme from './images/Creme.jpg';
import Konfiture from './images/Konfiture.jpg';
import saucen from './images/saucen.jpg';
import utl_ipt__info__icons from './images/utl-ipt__info__icons.svg';
import avatar_placeholder from './images/avatar_placeholder.jpg';
import featured_article from './images/featured_article.png';
import aktuell_img_1 from './images/aktuell_img_1.jpg';
import glass_2 from './images/glass_2.png';
import glass_1 from './images/glass_1.png';

import './images/AE0I1200.jpg';
import './images/AE0I1722.jpg';
import './images/hero-img.jpg';
import './images/AE0I1645.jpg';
import './images/AE0I1292.jpg';
import './images/placeholder_prod_img_75.png';
